import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Div100vh from "react-div-100vh"
import { motion } from "framer-motion"
import SEO from "../components/seo"
import * as styles from "../css/work.module.css"

const WorkPage = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "thumbnails" } }
        ) {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `
  )

  const images = () => {
    const output = []
    allFile.edges.map(edge => {
      if (edge.node.name.substring(3) === "cl-thank-you") {
        output.push(
          <Link
            className={styles.imgLink}
            to={`${edge.node.name.substring(3)}`}
          >
            <img
              className={styles.img}
              src={edge.node.publicURL}
              key={edge.node.id}
              draggable={false}
            />
          </Link>
        )
      } else {
        output.push(
          <Link
            className={styles.imgLink}
            to={`${edge.node.name.substring(3)}`}
          >
            <img
              className={styles.img}
              src={edge.node.publicURL}
              key={edge.node.id}
              draggable={false}
            />
          </Link>
        )
      }
    })
    return output
  }

  return (
    <Div100vh className={styles.container}>
      <SEO title="WORK" />
      <motion.div
        transition={{ delay: 0.5, duration: 1 }}
        animate={{ opacity: 1 }}
        className={styles.wrap}
      >
        {images()}
      </motion.div>
    </Div100vh>
  )
}

export default WorkPage
